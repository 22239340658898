import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useGlobalState } from "../state/globalState";

export interface FAQProps {
  data: {
    allSanityFaq: {
      edges: {
        node: {
          title: string;
          category: string;
          slug: {
            current: string;
          };
          _rawBody: any;
        };
      }[];
    };
  };
}

const FAQPage: React.FC<FAQProps> = ({ data: { allSanityFaq } }) => {
  const [faqQuery, setFaqQuery] = useQueryParam("id", StringParam);

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  const [collapseState, setCollapseState] = React.useState<number[]>(
    allSanityFaq.edges.map(() => 0)
  );

  const collapsibleRefs = React.useRef<(HTMLDivElement | null)[]>([]);

  React.useEffect(() => {
    if (faqQuery) {
      const queryIndex = allSanityFaq.edges.findIndex(
        ({ node }) => node.slug.current === faqQuery
      );
      if (queryIndex >= 0) {
        setTimeout(() => {
          handleQuestionOpen(queryIndex, true);
          collapsibleRefs.current[queryIndex]?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      }
    }
  }, [faqQuery]);

  const handleQuestionOpen = (index: number, alwaysOpen: boolean = false) => {
    const scrollHeight = collapsibleRefs.current[index]?.scrollHeight || 0;
    const newCollapsibleState = Object.assign([], collapseState, {
      [index]:
        collapseState[index] === 0
          ? scrollHeight
          : alwaysOpen
          ? scrollHeight
          : 0,
    });
    setCollapseState(newCollapsibleState);
  };

  return (
    <div className="py-24 container-4xl">
      <ul className="mt-16">
        {allSanityFaq.edges.map(({ node }, index) => (
          <li
            className="relative -mt-px border-t border-b border-gray-100"
            key={index}
          >
            <button
              type="button"
              className="w-full py-6 text-left"
              onClick={() => {
                handleQuestionOpen(index);
              }}
            >
              <div className="flex items-center justify-between text-primary">
                <h3 className="text-xl font-bold font-headline">
                  {node.title}
                </h3>
                {collapseState[index] > 0 ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-shrink-0"
                  >
                    {/* MINUS */}
                    <path
                      d="M20 11C20.5523 11 21 11.4477 21 12C21 12.5429 20.5673 12.9848 20.028 12.9996L20 13H4C3.44772 13 3 12.5523 3 12C3 11.4571 3.43267 11.0152 3.97201 11.0004L4 11H20Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-shrink-0"
                  >
                    {/* PLUS */}
                    <path
                      d="M12 3C12.5429 3 12.9848 3.43267 12.9996 3.97201L13 4V11H20C20.5523 11 21 11.4477 21 12C21 12.5429 20.5673 12.9848 20.028 12.9996L20 13H13V20C13 20.5523 12.5523 21 12 21C11.4571 21 11.0152 20.5673 11.0004 20.028L11 20V13H4C3.44772 13 3 12.5523 3 12C3 11.4571 3.43267 11.0152 3.97201 11.0004L4 11H11V4C11 3.44772 11.4477 3 12 3Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
              </div>
            </button>
            <div
              className="relative overflow-hidden transition-all duration-500 max-h-0"
              ref={(ref) => {
                if (!collapsibleRefs.current[index]) {
                  collapsibleRefs.current[index] = ref;
                }
              }}
              style={
                collapseState[index] > 0
                  ? {
                      maxHeight: `${collapseState[index]}px`,
                    }
                  : {}
              }
            >
              <BlockContent
                blocks={node._rawBody}
                projectId={process.env.GATSBY_SANITY_PROJECT_ID}
                dataset={process.env.GATSBY_SANITY_DATASET}
                className="mb-6 prose prose-lg text-gray-500"
                renderContainerOnSingleChild={true}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSanityFaq(
      filter: { i18n_lang: { eq: $language } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          category
          title
          slug {
            current
          }
          _rawBody
        }
      }
    }
  }
`;
